/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
          
$('.scroll-anchor').click(function(){
    $('html, body').animate({
        scrollTop: $( $.attr(this, 'href') ).offset().top
    }, 800);
    return false;
});
          
$('.contactLink').click(function(){
    $('.bar').removeClass('animate');
    $('.mobile-menu').removeClass('active');
});
          

$('.hamburger-menu').on('click', function() {
$('.bar').toggleClass('animate');
        $('.mobile-menu').toggleClass('active');
});
   
                 
          
// Scroll Reveal
window.sr = ScrollReveal({
    reset: false,
    opacity: 0,
    scale: 1,
    duration: 800,
    distance: '100px',
    mobile: false,
    viewFactor: 0.4
});
sr.reveal('.fadein');  
     
          
// Ticker
var width = $('.ticker-text').width(),
    containerwidth = $('.ticker-container').width(),
    left = containerwidth;
$(document).ready(function(e){
	function tick() {
        if(--left < -width){
            left = containerwidth;
        }
        $(".ticker-text").css("margin-left", left + "px");
        setTimeout(tick, 8);
      }
      tick();
});  
          
   
  
          
          
if ( $(window).width() > 769) {      

$(window).scroll(function() {    
    var scroll = $(window).scrollTop();
    if (scroll >= 400) {

        $(".banner").addClass("smaller");
    }
    else {
        $(".banner").removeClass("smaller");
    } 
});  
    
} 
else {
  //Add your javascript for small screens here 
}  
          
          
          
// test
var image = $('.image-with-thumbs > .thumb-wrap > .image'),
    thumb = $('.image-with-thumbs .thumb'),
    thumbWrap = $('.image-with-thumbs > .thumb-wrap');

// Control thumbnail loading
thumb.on('mouseover', function() {
    var $this = $(this),
    thumbUrl = $(this).attr('data-img-url');
    

  $this.addClass('active')
    .siblings('li').removeClass('active');


    
    thumbWrap.fadeOut('fast');
     
       setTimeout(function() {

           image.css("background-image", "url(" + thumbUrl + ")");
  
           
           
           thumbWrap.fadeIn('slow');
           
       }, 200);
    
  
    /*
      image.addClass('fadeIn').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function() {
        image.fadeOut('fadeIn')
      });
  */
    
}); 
        
// end test          
                    
      
$.stellar({
  responsive: true
});         
          
          
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
  
          
jQuery(document).ready(function(){
  $(window).scroll(function(e){
  	parallaxScroll();
	});
	 
	function parallaxScroll(){
		var scrolled = $(window).scrollTop();
		$('.entry-sec').css('opacity',(1-(scrolled*.0025)));
        $('.scroll-anchor').css('opacity',(1-(scrolled*.0025)));
	}
 
 });        
     
        
          

          
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about': {
      init: function() {
        // JavaScript to be fired on the about us page
          
(function($) {
    $('.accordion > li:eq(0) .topic').addClass('active').next().slideDown();

    $('.accordion .topic').click(function(j) {
        var dropDown = $(this).closest('li').find('.qcontent');

        $(this).closest('.accordion').find('.qcontent').not(dropDown).slideUp();

        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
        } else {
            $(this).closest('.accordion').find('topic.active').removeClass('active');
            $(this).addClass('active');
        }

        dropDown.stop(false, true).slideToggle();

        j.preventDefault();
    });
})(jQuery);
          
          
          
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
